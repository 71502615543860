import classNames from "classnames";
import { Link, graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { IoChevronForwardOutline } from "react-icons/io5";
import { OurProcess } from "../components/our-process";
import GoogleReviews from "../components/sections/google-reviews";
import Guarantee from "../components/sections/guarantee";
import HomeBackgroundSection from "../components/sections/home-background-section";
import { HomeVideo } from "../components/sections/home-video";
import Seo from "../components/seo";
import PrimaryTextButton from "../components/shared/primary-text-button";
import withLocation from "../components/shared/withLocation";
import CartContext from "../context/cart-context";
import CoverOnInFrontOfRockHouseImage from "../images/home-page/cover-on-in-front-of-rock-house.jpg";
import BadgeCheckImage from "../images/home-page/icons/badge-check.png";
import CogImage from "../images/home-page/icons/cog.png";
import InHouseImage from "../images/home-page/icons/in-house.png";
import InstallDrillImage from "../images/home-page/icons/install-drill.png";
import MeasureTapeImage from "../images/home-page/icons/measure-tape.png";
import OrderOnlineImage from "../images/home-page/icons/order-online.png";
import ScrewImage from "../images/home-page/icons/screw.png";
import TapeMeasureImage from "../images/home-page/icons/tape-measure.png";
import TrueFitImage from "../images/home-page/icons/true-fit.png";
import InstallationMeasureImage from "../images/home-page/installation-measure.jpg";
import { styles } from "../utilities/styles";

const componentStyles = {
  Mountainland: {
    linkColor: "text-yellow-darker",
    headingColored: "text-yellow-dark",
  },
  Wasatch: {
    linkColor: "text-green-dark",
    headingColored: "text-green",
  },
};

const IndexPage = ({ search }) => {
  const { setDiscountCode } = useContext(CartContext);

  useEffect(() => {
    const getDiscount = async () => {
      setDiscountCode(search.code);
    };
    if (search.code) {
      getDiscount();
    }
  }, [search, setDiscountCode]);

  const data = useStaticQuery(graphql`
    query HomePageQuery {
      allWpPage(filter: { isFrontPage: { eq: true } }) {
        edges {
          node {
            title
            seo {
              title
              metaDesc
            }
            pages {
              fieldGroupName
              h1Title

              homePageSettings {
                openingParagraph
                backgroundImage {
                  altText
                  gatsbyImage(width: 1800)
                }
              }
            }
            featuredImage {
              node {
                altText
              }
            }
          }
        }
      }
    }
  `);

  const homePageData = data.allWpPage.edges[0].node;

  return (
    <>
      <Seo
        title={
          homePageData?.pages?.h1Title ||
          homePageData?.seo?.title ||
          `${process.env.STATE_NAME}'s BEST Custom Window Well Covers`
        }
        keywords={[`window well covers`, `steel window covers`, `window cover`]}
        description={
          homePageData?.seo?.metaDesc ||
          "Keep your family safe with our custom window well covers. We offer polycarbonate and steel window well covers that keep the elements and debris out. Contact us to learn more!"
        }
      />
      <Helmet
        bodyAttributes={{
          class: "home-page",
        }}
      />

      <HomeBackgroundSection homePageData={homePageData} />

      <div
        className={classNames(
          "relative grid grid-cols-1 md:grid-cols-4 gap-6 w-full max-w-4xl mx-auto px-4 mt-8 z-20",
          "lg:-mt-24 lg:px-24"
        )}
      >
        <Link
          to="/compare-covers/"
          className={classNames(
            "transition-transform duration-300",
            "hover:-translate-y-1"
          )}
        >
          <div className="h-full bg-grey-lighter rounded-xl overflow-hidden">
            <StaticImage
              className="w-full h-40 object-cover"
              src="../images/home-cover.jpg.webp"
              alt="Window Well Covers"
            />
            <div className="p-4 md:px-6 md:py-8">
              <h3 className="text-default text-lg md:text-xl bold mb-2 text-grey-darkest">
                Window Well Covers
              </h3>
              <div
                className={classNames(
                  "block",
                  componentStyles[process.env.COMPANY_NAME_SHORTER].linkColor
                )}
              >
                Shop covers
              </div>
            </div>
          </div>
        </Link>
        <Link
          to="/window-well-liners/"
          className={classNames(
            "transition-transform duration-300",
            "hover:-translate-y-1"
          )}
        >
          <div className="h-full bg-grey-lighter rounded-xl overflow-hidden">
            <StaticImage
              className="w-full h-40 object-cover"
              src="../images/home-liner.jpg.webp"
              alt="Window Well Liners"
            />
            <div className="p-4 md:px-6 md:py-8">
              <h3 className="text-default text-lg md:text-xl bold mb-2 text-grey-darkest">
                Window Well Liners
              </h3>
              <div
                className={classNames(
                  "block",
                  componentStyles[process.env.COMPANY_NAME_SHORTER].linkColor
                )}
              >
                Shop liners
              </div>
            </div>
          </div>
        </Link>
        <Link
          to="/window-well-accessories/"
          className={classNames(
            "transition-transform duration-300",
            "hover:-translate-y-1"
          )}
        >
          <div className="h-full bg-grey-lighter rounded-xl overflow-hidden">
            <StaticImage
              className="w-full h-40 object-cover object-top"
              src="../images/home-accessories.jpg.webp"
              alt="Accessories"
            />
            <div className="p-4 md:px-6 md:py-8">
              <h3 className="text-default text-lg md:text-xl bold mb-2 text-grey-darkest">
                Accessories
              </h3>
              <div
                className={classNames(
                  "block",
                  componentStyles[process.env.COMPANY_NAME_SHORTER].linkColor
                )}
              >
                Shop accessories
              </div>
            </div>
          </div>
        </Link>
        <Link
          to="/products/"
          className={classNames(
            "transition-transform duration-300",
            "hover:-translate-y-1"
          )}
        >
          <div className="h-full bg-grey-lighter rounded-xl overflow-hidden">
            <StaticImage
              className="w-full h-40 object-cover object-top"
              src="../images/black-steel-11-scaled-1.jpg.webp"
              alt="Shop all window well cover products"
            />
            <div className="p-4 md:px-6 md:py-8">
              <h3 className="text-default text-lg md:text-xl bold mb-2 text-grey-darkest">
                Shop All Products
              </h3>
              <div
                className={classNames(
                  "block",
                  componentStyles[process.env.COMPANY_NAME_SHORTER].linkColor
                )}
              >
                View products
              </div>
            </div>
          </div>
        </Link>
      </div>

      <div className={classNames("max-w-4xl mx-auto px-4 mt-24", "lg:px-24")}>
        <OurProcess />
      </div>

      <div className="mt-24">
        <Guarantee />
      </div>

      {featureSections.map((section, index) => (
        <section
          key={index}
          className={classNames("px-4 py-12 mt-12", "lg:px-0")}
        >
          <div className={classNames("max-w-4xl mx-auto", "lg:px-24")}>
            <div className="grid lg:grid-cols-2 gap-6">
              <div>
                <h2 className={styles.h2}>{section.title}</h2>
              </div>
              <div></div>
              {section.blocks.map((block, blockIndex) => {
                if (block.type === "image") {
                  return (
                    <img
                      alt="Custom Window Well Covers"
                      className="w-full h-full object-cover rounded-xl"
                      key={blockIndex}
                      src={block.image}
                    />
                  );
                }
                return (
                  <div
                    className={classNames(
                      "bg-grey-lighter p-6 rounded-xl min-h-[360px]",
                      "lg:p-8"
                    )}
                    key={blockIndex}
                  >
                    <img
                      alt={block.title}
                      height={72}
                      src={block.image}
                      width={72}
                    />
                    <h3 className={styles.h5}>{block.title}</h3>
                    <p className="font-light mt-3 text-base">{block.content}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classNames("max-w-4xl mx-auto", "lg:px-24")}>
            <Link
              to={section.learnMoreLink}
              className={classNames(
                "flex-1 flex justify-between flex-wrap items-center mt-6 group bg-grey-lighter rounded-2xl transition-colors",
                "lg:px-8 lg:gap-8 lg:bg-white lg:hover:bg-grey-lighter"
              )}
            >
              <div className={classNames("flex items-center", "lg:gap-8")}>
                <StaticImage
                  src="../images/home-page/icons/info.png"
                  alt="Learn more"
                />
                <p className={styles.h4}>{section.learnMoreText}</p>
              </div>
              <div
                className={classNames(
                  "hidden transition-all",
                  "lg:block",
                  "group-hover:translate-x-1"
                )}
              >
                <IoChevronForwardOutline />
              </div>
            </Link>
          </div>
        </section>
      ))}

      <section
        className={classNames("px-4 mt-24 max-w-4xl mx-auto", "lg:px-24")}
      >
        <div
          className={classNames(
            "relative flex items-center justify-center min-h-[50vh] text-center rounded-2xl overflow-hidden group"
          )}
        >
          <div className={"absolute inset-0 rounded-2xl"}>
            <StaticImage
              alt="Window Well Covers"
              className={classNames(
                "absolute inset-0 rounded-2xl transition-transform duration-300 w-full h-full",
                "group-hover:scale-105"
              )}
              objectFit="cover"
              objectPosition="center bottom"
              placeholder="blurred"
              src={"../images/home-page/poly-6.jpg"}
            />
          </div>
          <div className="bg-black/20 absolute inset-0" />
          <div className="relative grid gap-8">
            <h2 className={classNames(styles.h2, "text-white")}>
              Order a Custom Window Well Cover
            </h2>
            <div>
              <PrimaryTextButton to="/window-well-covers/">
                Shop Covers
              </PrimaryTextButton>
            </div>
          </div>
        </div>
      </section>

      <section className={classNames("relative px-4 mt-24", "lg:px-0")}>
        <div
          className={classNames(
            "pt-8 pb-24 w-full max-w-4xl grid gap-8 mx-auto",
            "lg:px-24"
          )}
        >
          <div>
            <h3 className={styles.h2}>
              Quality Custom Covers
              <br />
              from{" "}
              <span
                className={
                  componentStyles[process.env.COMPANY_NAME_SHORTER]
                    .headingColored
                }
              >
                Start to Finish
              </span>
            </h3>
          </div>

          <HomeVideo />

          <div className="lg:hidden mt-8 lg:mt-0 leading-normal prose mx-auto text-left">
            <p>
              Get peace of mind by having custom window well covers from{" "}
              <a className="underline" href="/window-well-covers/">
                {process.env.COMPANY_NAME}.
              </a>
            </p>
            <p>
              We lead the window well cover industry in not only customer
              service but also in providing the highest quality custom window
              well covers available in {process.env.STATE_NAME}.
            </p>
            <p>
              Available in two distinct styles, polycarbonate, and steel, every
              window well cover built by {process.env.COMPANY_NAME_SHORT} comes
              with a lifetime structural guarantee!
            </p>
          </div>
        </div>
      </section>

      <section
        className={classNames("mt-24 px-4 mx-auto max-w-4xl", "lg:px-24")}
      >
        <div
          className={classNames(
            "grid lg:grid-cols-4 bg-grey-lighter rounded-2xl p-8 mx-auto",
            "lg:p-24"
          )}
        >
          <div className="flex justify-center items-center">
            <StaticImage
              alt="Phone call"
              src="../images/home-page/icons/phone-call.png"
              className="max-w-[140px]"
            />
          </div>
          <div className="lg:col-span-3 text-xl font-bold grid gap-8">
            <div>
              We offer easy online ordering and support via phone or chat. After
              ordering, you'll meet your dedicated service representative and
              technician with a photo for identification. We respect your
              communication preferences and provide regular updates to ensure
              quality and satisfaction.
              <br />
              <span className={`text-${process.env.THEME_COLOR_PRIMARY}-dark`}>
                Have a question?
              </span>
            </div>
            <div>
              <PrimaryTextButton to="/contact-us/">
                Contact Us
              </PrimaryTextButton>
            </div>
          </div>
        </div>
      </section>

      <section className={classNames("px-4 mt-24", "lg:px-0")}>
        <div className={classNames("mx-auto w-full max-w-4xl", "lg:px-24")}>
          <h2 className={classNames(styles.h2, "mb-6", "md:max-w-[48%]")}>
            Shop Our{" "}
            <span
              className={
                componentStyles[process.env.COMPANY_NAME_SHORTER].headingColored
              }
            >
              Products &amp; Accessories
            </span>
          </h2>
          <div className="grid md:grid-cols-2 gap-6">
            <Link
              className={classNames(
                "duration-300 flex justify-start items-end relative px-12 py-8 min-h-[330px] rounded-2xl transition-transform",
                "hover:-translate-y-1"
              )}
              to="/window-well-covers/"
            >
              <div className="absolute inset-0 object-contain">
                <StaticImage
                  alt="Window Well Covers"
                  className="rounded-2xl w-full h-full"
                  src="../images/home-cover.jpg.webp"
                />
              </div>
              <div className="relative text-white">
                <h3 className={classNames(styles.h3, "text-white")}>
                  Window Well Covers
                </h3>
                <p>Shop covers</p>
              </div>
            </Link>
            <Link
              className={classNames(
                "duration-300 flex justify-start items-end relative px-12 py-8 min-h-[330px] rounded-2xl transition-transform",
                "hover:-translate-y-1"
              )}
              to="/window-well-liners/"
            >
              <div className="absolute inset-0 object-contain">
                <StaticImage
                  src="../images/home-page/product-liners.jpg"
                  alt="Accessory products"
                  className="rounded-2xl w-full h-full"
                />
              </div>
              <div className="relative text-white">
                <h3 className={classNames(styles.h3, "text-white")}>
                  Window Well Liners
                </h3>
                <p>Shop liners</p>
              </div>
            </Link>
            <Link
              className={classNames(
                "duration-300 flex justify-start items-end relative px-12 py-8 min-h-[330px] rounded-2xl transition-transform",
                "hover:-translate-y-1"
              )}
              to="/window-well-accessories/"
            >
              <div className="absolute inset-0 object-contain">
                <StaticImage
                  src="../images/home-page/product-accessories.jpg"
                  alt="Accessory products"
                  className="rounded-2xl w-full h-full"
                />
              </div>
              <div className="relative text-white">
                <h3 className={classNames(styles.h3, "text-white")}>
                  Window Well Accessories
                </h3>
                <p>Shop accessories</p>
              </div>
            </Link>
            <Link
              className={classNames(
                "duration-300 flex justify-start items-end relative px-12 py-8 min-h-[330px] rounded-2xl transition-transform",
                "hover:-translate-y-1"
              )}
              to="/products/"
            >
              <div className="absolute inset-0 object-contain">
                <StaticImage
                  src="../images/home-page/product-all.jpg"
                  alt="Accessory products"
                  className="rounded-2xl w-full h-full"
                />
              </div>
              <div className="relative text-white">
                <h3 className={classNames(styles.h3, "text-white")}>
                  All Products
                </h3>
                <p>Shop products</p>
              </div>
            </Link>
          </div>
        </div>
      </section>

      <section className="mt-32">
        <GoogleReviews />
      </section>
    </>
  );
};

const featureSections = [
  {
    title: (
      <>
        Do You Really Need <br />
        <span
          className={
            componentStyles[process.env.COMPANY_NAME_SHORTER].headingColored
          }
        >
          Custom
        </span>{" "}
        Covers?
      </>
    ),
    learnMoreLink: "/why-custom-matters/",
    learnMoreText: "Learn more about our custom covers",
    blocks: [
      {
        type: "content",
        image: CogImage,
        title: "Why Custom?",
        content:
          "Not all window wells are the same size, a custom window well cover is the only way to prevent gaps. Our custom window well covers look like they were done by a professional instead of looking like an afterthought.",
      },
      {
        type: "image",
        image: InstallationMeasureImage,
      },
      {
        type: "content",
        image: MeasureTapeImage,
        title: "Templates & measurements",
        content:
          "Getting a cover to fit properly on the inside lip of a window well is a painstaking process.  First, precise measurements are needed to create a template of the window well.  It then takes a repeatable and consistent manufacturing process with very little tolerance to re-create a cover to perfectly match the template.",
      },
      {
        type: "content",
        image: TrueFitImage,
        title: "True Custom-Fit",
        content:
          "Some competitors call their covers ‘custom-fit’ when in reality are not.  A true custom-fit cover will fit snugly on the inside lip of the window well.  Admittedly, this true custom inner fit is difficult to consistently accomplish so we understand why our competitors don’t even try.",
      },
    ],
  },
  {
    title: (
      <>
        Unrivaled{" "}
        <span
          className={
            componentStyles[process.env.COMPANY_NAME_SHORTER].headingColored
          }
        >
          quality
        </span>{" "}
        on each individual window well cover
      </>
    ),
    learnMoreLink: "/high-quality/",
    learnMoreText: "Learn more about our unrivaled quality",
    blocks: [
      {
        type: "content",
        image: BadgeCheckImage,
        title: "Lifetime Structural Guarantee!",
        content:
          "We have complete confidence in the craftsmanship and quality of our products, which is why we proudly offer a lifetime structural warranty on all of our window well covers. This warranty serves as a testament to the durability and reliability of our covers, assuring you that they are built to last.",
      },
      {
        type: "content",
        image: ScrewImage,
        title: "Quality Materials",
        content:
          "We pride ourselves on using only the finest materials available to craft our premium window well covers and accessories. Our commitment to quality ensures that every product we offer is made from the highest grade materials, providing exceptional durability, protection, and aesthetic appeal for your home.",
      },
      {
        type: "image",
        image: CoverOnInFrontOfRockHouseImage,
      },
      {
        type: "content",
        image: InHouseImage,
        title: "In-House Design & Production",
        content:
          "After more than 5 years of research, we are proud to say we have implemented the only automated manufacturing process in the window well cover industry. We hire the best local talent to design, weld and produce all of our products. Year after year we continue to invest in our process and products, we use the best machinery available.",
      },
    ],
  },
  {
    title: (
      <>
        Our{" "}
        <span
          className={
            componentStyles[process.env.COMPANY_NAME_SHORTER].headingColored
          }
        >
          Process
        </span>
        : You Order, We Measure, We Install.
      </>
    ),
    learnMoreLink: "/hassle-free/",
    learnMoreText: "Learn more about our process",
    blocks: [
      {
        type: "content",
        image: OrderOnlineImage,
        title: "Order Online or Call",
        content:
          "Pick a style you like and choose the accessories you need and let us do the rest! Place your order online or call us for any questions. Pro tip: You get discounts by ordering online and get the exact same quality service!",
      },
      {
        type: "content",
        image: TapeMeasureImage,
        title: "We Measure",
        content:
          "Our technicians will visit your location, equipped with precise measuring tools and equipment, to accurately measure your window wells.",
      },
      {
        type: "content",
        image: InstallDrillImage,
        title: "Build & Install",
        content:
          "Our skilled welders and team fabricate the covers in our workshop using high-quality materials and equipment. Once completed, we come to your location to install the covers, along with any necessary ladders, locks, or EZ Lifts, ensuring everything is securely and properly fitted.",
      },
      {
        type: "image",
        image: CoverOnInFrontOfRockHouseImage,
      },
    ],
  },
];

export default withLocation(IndexPage);
