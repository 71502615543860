import React, { useEffect, useRef, useState } from "react";

const videoUrl = {
  Mountainland: "https://www.youtube-nocookie.com/embed/Kaj0G-zV3Mc",
  Wasatch: "https://www.youtube-nocookie.com/embed/CiQC6Qtw4Aw",
};

export const HomeVideo = () => {
  const [shouldLoad, setShouldLoad] = useState(false);

  // use intersection observer to only load the video when it's in view
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) =>
      entries.forEach((entry) => entry.isIntersecting && setShouldLoad(true))
    );
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  return (
    <iframe
      ref={ref}
      width="560"
      height="315"
      src={shouldLoad ? videoUrl[process.env.COMPANY_NAME_SHORTER] : ""}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      className="aspect-video h-full w-full rounded-2xl"
    ></iframe>
  );
};
